/**
 * @module mapboxgl-map-adapter
 */

import { MapboxglMapAdapter } from './MapboxglMapAdapter';

import type { MapboxglMapAdapterOptions } from './MapboxglMapAdapter';

export type { MapboxglMapAdapterOptions };

export default MapboxglMapAdapter;
